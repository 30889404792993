const input = {
    username: 'Username',
    password: 'Password',
    email: 'Email',
    phone_number: 'Phone number',
    confirm_password: 'Confirm password',
    search_region_code: 'Search region code',
    backup_code: 'Backup code',
};
export default Object.freeze(input);
